<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row class="userDetailWrapper">
            <b-col xl="12">
              <b-row>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Offer Date</h5>
                  <p>
                    {{
                      offerDetailObject.offerDate
                        ? offerDetailObject.offerDate
                        : "N/A"
                    }}
                  </p>
                  <h5 class="bold">Email Id</h5>
                  <p style="text-transform: capitalize !important">
                    {{
                      offerDetailObject.emailId
                        ? offerDetailObject.emailId
                        : "N/A"
                    }}
                  </p>
                  <h5 class="bold">Status</h5>
                  <p style="text-transform: capitalize !important">
                    {{
                      offerDetailObject.status
                        ? offerStatusGetterArray[offerDetailObject.status - 1]
                            .displayName
                        : "N/A"
                    }}
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Property Name</h5>
                  {{
                    offerDetailObject.propertyName
                      ? offerDetailObject.propertyName
                      : "N/A"
                  }}
                  <h5 class="bold">Phone Number</h5>
                  <p>
                    {{
                      offerDetailObject.phoneNumber
                        ? offerDetailObject.phoneNumber
                        : "N/A"
                    }}
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Buyer Name</h5>
                  <p>
                    {{
                      offerDetailObject.BuyerName
                        ? offerDetailObject.BuyerName
                        : "N/A"
                    }}
                  </p>
                  <h5 class="bold">Offer Price</h5>
                  <p>
                    {{
                      offerDetailObject.offerPrice
                        ? offerDetailObject.offerPrice
                        : "N/A"
                    }}
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Seller Name</h5>
                  <p>
                    {{
                      offerDetailObject.sellerName
                        ? offerDetailObject.sellerName
                        : "N/A"
                    }}
                  </p>
                  <h5 class="bold">Document</h5>
                  <p v-if="offerDetailObject.document">
                    <img
                      src="@/assets/images/svg/document.svg"
                      alt="pof_file"
                      @click.prevent="
                        downloadFile(
                          offerDetailObject.document,
                          offerDetailObject.documentName
                        )
                      "
                      style="cursor: pointer"
                    />
                  </p>
                  <p v-else>N/A</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" v-if="showSpinner" class="d-flex justify-content-center">
        <b-spinner style="width: 50px; height: 50px" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { downloadDocument } from "@/utils/commonMethods";
import moment from "moment";
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      items: [],
      users: [],

      offerDetailObject: {},
    };
  },

  created() {
    var self = this;
    self.showSpinner = true;
    let propertyName = "";
    let userName = "";
    self.offerDetailObject = {};
    self.userData();
    self
      .offerData(self.$route.params.id)
      .then(() => {
        if (self.allUserDataArray.length > 0 && self.getOfferArray.length > 0) {
          // console.log('getIdSpecificPropertyArray',self.getOfferArray,self.allUserDataArray);
          self
            .idSpecificPropertyData(self.getOfferArray[0].propertyId)
            .then(() => {
              // console.log('getIdSpecificPropertyArray',self.getIdSpecificPropertyArray);
              if (self.getIdSpecificPropertyArray.length > 0) {
                if (
                  self.getIdSpecificPropertyArray[0].listingType ===
                  "free_or_public"
                ) {
                  propertyName = `${self.getIdSpecificPropertyArray[0].contractBCountry}, ${self.getIdSpecificPropertyArray[0].contractBState}`;
                } else if (
                  self.getIdSpecificPropertyArray[0].listingType ===
                  "half_way_or_shared" || self.getIdSpecificPropertyArray[0].listingType == 'mental_or_detox_facility' || self.getIdSpecificPropertyArray[0].listingType == 'free_or_public_shelter'
                ) {
                  propertyName = `${self.getIdSpecificPropertyArray[0].leaseBCountry}, ${self.getIdSpecificPropertyArray[0].leaseBState}`;
                } else if (
                  self.getIdSpecificPropertyArray[0].listingType ===
                  "mental_or_detox"
                ) {
                  propertyName = `${self.getIdSpecificPropertyArray[0].ownerBCountry}, ${self.getIdSpecificPropertyArray[0].ownerBState}`;
                }
              } else {
                propertyName = "";
              }
              var index = self.allUserDataArray.findIndex((item) => {
                return item.id == self.getOfferArray[0].userId;
              });
              if (index != -1) {
                userName = `${self.allUserDataArray[index].firstName} ${self.allUserDataArray[index].lastName}`;
              } else {
                userName = "";
              }
              self.offerDetailObject = {
                offerDate: self.getOfferArray[0].createdAt
                  ? moment(
                      self.getOfferArray[0].createdAt.seconds * 1000
                    ).format("DD/MM/YYYY")
                  : "",
                propertyName: propertyName != "" ? propertyName : "",
                BuyerName: self.getOfferArray[0].fullName,
                sellerName: userName,
                emailId: self.getOfferArray[0].emailId,
                phoneNumber: self.getOfferArray[0].phoneNumber,
                offerPrice: "$" + self.getOfferArray[0].offerPrice,
                document: self.getOfferArray[0].pof,
                documentName: self.getOfferArray[0].pofName,
                status: self.getOfferArray[0].status,
              };
              self.showSpinner = false;
            })
            .catch((error) => {
              self.showSpinner = false;
              console.log(
                "ERROR in get property data in offerdetail page",
                error
              );
            });
        }
      })
      .catch((error) => {
        self.showSpinner = false;
        console.log("ERROR in get offer data in offerdetail page", error);
      });
  },

  methods: {
    ...mapActions({
      offerData: "offer/offerData",
      userData: "user/userData",
      idSpecificPropertyData: "property/idSpecificPropertyData",
    }),
    downloadFile(url, name) {
      downloadDocument(url, name);
    },
  },

  computed: {
    ...mapGetters({
      getOfferArray: "offer/getOffer",
      allUserDataArray: "user/allUserData",
      getIdSpecificPropertyArray: "property/getIdSpecificProperty",
      offerStatusGetterArray: "admin/offerStatusGetter",
    }),
  },
};
</script>

<style>
.activeTab {
  color: #17D1C6;
  font-weight: 600;
}
</style>